import React, { useEffect } from "react";
import icon from "../assets/contactus/company-logo.png";
import { MdEmail } from "react-icons/md";
import { FaSquarePhone } from "react-icons/fa6";
const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        id="contactus"
        className=" w-full mb-[5%] mt-[3%] text-center text-[35px] text-[#FDDC59] max-[800px]:text-[30px] max-[800px]:mt-[5%] max-[680px]:text-[25px] max-[570px]:text-[20px] max-[460px]:text-[18px]"
      >
        <span className="ring-2 ring-[#69564C] ring-offset-1 bg-[#69564C] rounded-[10px] p-[10px] px-[20px] font-[600]">
          संपर्क करें
        </span>
      </div>
      <div className="flex flex-wrap items-center w-[85%] justify-between mx-[7.5%] my-[5%] max-[1388px]:justify-center max-[1388px]:gap-[50px] max-[1080px]:w-[90%] max-[1080px]:mx-[5%] max-[1010px]:w-[95%] max-[1010px]:mx-[2.5%] max-[941px]:justify-center max-[941px]:w-[80%] max-[941px]:mx-[10%] max-[941px]:items-center max-[606px]:w-[90%] max-[627px]:mx-[5%]">
        <div className="flex justify-center items-center flex-wrap max-[941px]:mb-[20px] max-[1388px]:gap-[40px]">
          <div className="text-[#69564C] flex items-center flex-wrap max-[1388px]:w-[100%] max-[1388px]:justify-center">
            <img src={icon} alt="" />
            <h1 className="text-[30px] mx-[10px] font-extrabold  max-[681px]:text-center">
              HERDSMAN <br />
              DEVELOPMENT <br />
              PRIVATE LIMITED
            </h1>
          </div>
          <p className="mx-[10px] text-[#7AAB00] text-center text-[20px] font-bold">
            3<sup>rd</sup> Floor, Orchid Center,
            <br /> Golf Course Road,
            <br /> Sec-53, Gurugram,
            <br /> 122002
          </p>
        </div>
        <div className="flex flex-col justify-center text-[20px] max-[450px]:text-[16px] max-[360px]:text-[15px]">
          <div className="mb-[30px]">
            <a
              href="mailto:info@pashusevakpragatikendra.com"
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-[10px]"
            >
              <MdEmail className="text-[50px] text-[#7AAB00] hover:text-[#677938]" />
              <span className="text-[#69564C] font-[600]">
                {" "}
                info@pashusevakpragatikendra.com
              </span>
            </a>
          </div>
          <div className="">
            <a
              href="tel:8059512356"
              rel="noreferrer"
              className="flex items-center gap-[10px]"
            >
              <FaSquarePhone className="text-[50px] text-[#7AAB00] hover:text-[#677938]" />
              <span className="text-[#69564C] font-[600]">+918059512356</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
