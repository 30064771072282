import React, { useEffect, useState } from "react";
import Hometopcontainer from "../components/hometopcontainer/hometopcontainer";
import Benefits from "../components/benefits/benefits";
import Ourinitiatives from "./ourinitiatives";
import Aboutus from "./aboutus";
import Contactus from "./contactus";
import Videos from "./videos";
import Aos from "aos";
const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 2000 });
  }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when the window is resized
    window.addEventListener("resize", handleResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log(windowWidth);
  return (
    <>
      <Hometopcontainer />
      <Benefits />
      {windowWidth < 800 && (
        <>
          <Ourinitiatives />
          <Aboutus />
          <Videos />
          <Contactus />
        </>
      )}
    </>
  );
};

export default Homepage;
