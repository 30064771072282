import React from "react";
import logo from "../../assets/headers/logo-2.png";
import { NavLink } from "react-router-dom";
import icon from "../../assets/headers/logo.png";
// <div className="border-2 border-solid rounded-[20px] p-[10px] flex w-max max-[800px]:w-[75%]">
//   <img
//     src={logo}
//     alt="logo"
//     className="w-[70px] h-[100%] max-[1024px]:ml-[20px] max-[840px]:ml-[10px]"
//   />
//   <div className="w-[5px] h-[100%] bg-yellow-400"></div>
//   <div className="ml-[5px] flex flex-col justify-center items-center py-[4px]">
//     <span className="font-bold text-[13px] text-[#75A018]">
//       पशुसेवक प्रगति केन्द्र
//     </span>
//     <span className="text-[#69564C] font-bold text-[13px]">
//       पशुपालक का सच्चा साथी
//     </span>
//   </div>
// </div>
const Headers = () => {
  return (
    <div className="z-10 border-b-4 border-solid border-[#69564C] border-l-4 border-r-4 sticky top-0 left-0 w-full h-[100px] p-[5px] flex justify-between bg-gray-100">
      <img
        src={icon}
        alt=""
        className="ml-[40px] max-[980px]:ml-[20px] max-[890px]:ml-[10px] max-[818px]:ml-[5px] max-[800px]:ml-[30px]"
      />
      <div className="w-max  flex items-center justify-end mr-[40px] max-[1150px]:mr-[20px] max-[800px]:hidden max-[890px]:mr-[10px] max-[818px]:mr-[5px]">
        <ul className="text-[20px] max-[960px]:text-[19px] max-[930px]:text-[18px] max-[860px]:text-[17px] flex gap-[40px] justify-center items-center max-[1150px]:gap-[30px] max-[1080px]:gap-[20px] max-[1030px]:gap-[10px] max-[900px]:gap-[7px] max-[815px]:gap-[7px] max-[870px]:gap-[5px] max-[830px]:gap-[3px] max-[815px]:gap-[1px]">
          <NavLink to="/">
            <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
              Home
            </li>
          </NavLink>
          <NavLink to="/ourinitiative">
            <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
              Our Initiatives
            </li>
          </NavLink>
          <NavLink to="/video">
            <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
              Videos
            </li>
          </NavLink>
          <NavLink to="/aboutus">
            <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
              About Us
            </li>
          </NavLink>
          <NavLink to="/contactus">
            <li className="p-[6px] px-[10px] rounded-[10px] cursor-pointer font-bold text-[#69564C] hover:bg-gray-300">
              Contact Us
            </li>
          </NavLink>
          <NavLink to="https://forms.gle/ZF82BkXi4mmUMsQa6">
            <li className="p-[5px] px-[10px] rounded-[10px] cursor-pointer bg-[#69564C] hover:bg-[#FDDC59] text-[#FDDC59] hover:text-[#69564C] font-[600]">
              PashuSevak
              <br />
              Registration
            </li>
          </NavLink>
        </ul>
      </div>
    </div>
  );
};

export default Headers;
