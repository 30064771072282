import React, { useEffect, useState } from "react";
import icon1 from "../../assets/vission/image.png";
import icon2 from "../../assets/vission/v-1-1.png";
import icon3 from "../../assets/vission/v-2-2.png";
import icon4 from "../../assets/vission/v-3.png";
import icon5 from "../../assets/vission/v-4-4.png";
// import Aos from "aos";
const Vission = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when the window is resized
    window.addEventListener("resize", handleResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className=" w-full my-[4%] text-center text-[35px] text-[#FDDC59] max-[800px]:text-[30px] max-[680px]:text-[25px] max-[570px]:text-[20px] max-[460px]:text-[15px]">
        <span className="ring-2 ring-[#69564C] ring-offset-1 bg-[#69564C] rounded-[10px] p-[10px] px-[20px] font-[600]">
          हमारा दृष्टिकोण
        </span>
      </div>
      <div className="px-[8%] mb-[3%] overflow-hidden font-bold text-[#7AAB00] flex w-full flex-wrap justify-between text-[25px] max-[1248px]:justify-center max-[1000px]:text-[22px]  max-[1000px]:px-[4%] max-[868px]:px-[1%] max-[868px]:text-[20px] max-[680px]:justify-center max-[500px]:text-[18px] max-[408px]:text-[16px]">
        <div className="border-l-[10px] mx-[10px] border-b-[10px] border-[#7AAB00] border-solid  rounded-bl-[3.125rem] pl-[20px] py-[20px] w-[31.5rem]">
          <div
            data-aos="fade-left"
            data-aos-once="true"
            data-aos-offset="200"
            className="ring-2  ring-offset-1 ring-[#69564C] shadow-2xl p-[10px] h-full border-4 flex border-[#69564C] border-solid rounded-[20px]"
          >
            <img src={icon1} alt="" className="w-[40%]" />
            <span className="h-full text-center flex justify-center items-center p-[10px] ">
              हमारा लक्ष्य अप्रैल 2025 तक कम से कम 51 पशुसेवक प्रगति केंद्र
              खोलने का है।
            </span>
          </div>
        </div>
        <div className="border-r-[10px] mx-[10px] border-b-[10px] border-[#7AAB00] border-solid rounded-br-[3.125rem] pr-[20px] py-[20px] w-[31.5rem]">
          <div
            data-aos="fade-right"
            data-aos-once="true"
            data-aos-offset="200"
            className="ring-2  ring-offset-1 ring-[#69564C] shadow-2xl px-[10px] h-full border-4 flex justify-center items-center border-[#69564C] border-solid rounded-[20px]"
          >
            <img src={icon4} alt="" className="size-[50%]" />
            <span className="h-full text-center flex justify-center items-center">
              हम पशुसेवकों/पशुपालको/कृषि उद्यमियों को सरकार द्वारा दिए जाने वाले
              अनुदान/सब्सिडी के बारे में शिक्षित/प्रशिक्षित और सुविधा प्रदान
              करेंगे।
            </span>
          </div>
        </div>
        <div className="border-r-[10px] mx-[10px] max-[1247px]:h-[280px] border-b-[10px] border-[#7AAB00] border-solid  rounded-br-[3.125rem] max-[1248px]:border-r-0 max-[1248px]:border-l-[10px] max-[1248px]:rounded-bl-[3.125rem] max-[1248px]:rounded-br-[0rem] max-[1248px]:pr-0 max-[1248px]:pl-[20px] pr-[20px] py-[20px] w-[31.5rem]">
          <div
            data-aos={windowWidth < 1248 ? "fade-left" : "fade-right"}
            data-aos-once="true"
            data-aos-offset="200"
            className="ring-2  ring-offset-1 ring-[#69564C] shadow-2xl h-full border-4 flex justify-center items-center border-[#69564C] border-solid rounded-[20px]"
          >
            <img src={icon2} alt="" className="size-[40%]" />
            <span className="h-full text-center flex justify-center items-center p-[10px]">
              पशुपालन को सुविधाजनक और लाभदायक बनाना
            </span>
          </div>
        </div>
        <div className="border-l-[10px] mx-[10px] border-b-[10px] border-[#7AAB00] border-solid max-[1248px]:border-l-0 max-[1248px]:border-r-[10px] max-[1248px]:rounded-bl-[0rem] max-[1248px]:rounded-br-[3.125rem] max-[1248px]:pl-0 max-[1248px]:pr-[20px] rounded-bl-[3.125rem] pl-[20px] py-[20px] w-[31.5rem]">
          <div
            data-aos={windowWidth < 1248 ? "fade-right" : "fade-left"}
            data-aos-once="true"
            data-aos-offset="200"
            className="ring-2  ring-offset-1 ring-[#69564C] shadow-2xl px-[10px] h-full border-4 flex justify-center items-center border-[#69564C] border-solid rounded-[20px]"
          >
            <img
              src={icon5}
              alt=""
              className="size-[60%] max-[370px]:size-[56%]"
            />
            <span className="h-full text-center flex justify-center items-center">
              हम पशुसेवकों/पशुपालको/कृषि उद्यमियों को उनके लाभदायक
              व्यवसाय, स्थापना और विस्तार के बारे में शिक्षित/प्रशिक्षित और
              सुविधा प्रदान करेंगे।
            </span>
          </div>
        </div>
        <div className="border-l-[10px] mx-[10px] border-b-[10px] border-[#7AAB00] border-solid  rounded-bl-[3.125rem] pl-[20px] py-[20px] w-[31.5rem]">
          <div
            data-aos="fade-left"
            data-aos-once="true"
            data-aos-offset="200"
            className="ring-2  ring-offset-1 ring-[#69564C] shadow-2xl h-full flex justify-center items-center border-4 border-[#69564C] border-solid rounded-[20px]"
          >
            <img
              src={icon3}
              alt=""
              className="size-[70%] max-[386px]:size-[60%] max-[367px]:size-[55%] max-[355px]:size-[52%]"
            />
            <span className="h-full text-center flex justify-center items-center p-[10px]">
              हम बकरी पालन को प्रोत्साहित करेंगे और बकरी के दूध उत्पाद बेचने
              के लिए चैनल/नेटवर्क विकसित करेंगे।
            </span>
          </div>
        </div>
        <div className="border-r-[10px] mx-[10px] border-b-[10px] border-[#7AAB00] border-solid  rounded-br-[3.125rem] pr-[20px] py-[20px] w-[31.5rem]">
          <div
            data-aos="fade-right"
            data-aos-once="true"
            data-aos-offset="200"
            className="ring-2  ring-offset-1 ring-[#69564C] shadow-2xl p-[10px] h-full flex border-4 border-[#69564C] border-solid rounded-[20px]"
          >
            <img src={icon1} alt="" className="w-[40%]" />
            <span className="h-full text-center flex justify-center items-center p-[10px]">
              हम अपने पशुसेवक प्रगति केंद्र द्वारा पशु आहार को
              बेचकर गांव के एक व्यक्ति के लिए आय का स्रोत उत्पन्न  करेंगे।
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vission;
