import React, { useEffect } from "react";

const Videos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col gap-[50px] justify-center items-center my-[50px]">
      <div className="w-[80%] h-[600px] max-[1250px]:h-[550px] max-[1000px]:h-[500px] max-[1000px]:w-[85%] max-[840px]:h-[450px] max-[800px]:w-[90%] max-[650px]:h-[400px] max-[560px]:h-[320px] max-[460px]:h-[270px] max-[400px]:h-[240px] max-[380px]:h-[220px] ring-4 ring-offset-1 border-4 border-white border-solid">
        <iframe
          className="w-[100%] h-[100%]"
          src="https://www.youtube.com/embed/DraV5ZDUX6E"
        ></iframe>
      </div>
    </div>
  );
};

export default Videos;
