import React, { useEffect } from "react";
import Mission from "../components/mission/mission";
import Vission from "../components/vission/vission";
import Aos from "aos";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <Mission />
      <Vission />
    </>
  );
};

export default Aboutus;
